.control {
    --size: var(--checkbox_size, 1.25rem);
    --background: var(--form_background, var(--color-white));
    --color: var(--form_color, var(--color-black));
    --focus-color: var(--form_focus-color, var(--color-blue));
    --border-color: var(--form_border-color, var(--color-white-darken-5));
    --border-radius: var(--form_border-radius, var(--global-border-radius));
    margin: 0 auto calc(2 * var(--gap));
    display: flex;
    flex-direction: column;
    position: relative;
    transition: color var(--transition-out) var(--transition-timing);

    &:hover,
    &:focus,
    &:focus-within {
        color: hsl(var(--focus-color));
    }
}

.wrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    position: relative;
}

.checkbox {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:not([disabled]):focus {

        &:checked {

            + .icons {
                box-shadow: 0 0 0 2px hsla(var(--color-white), 1), 0 0 0 3px hsla(var(--focus-color), 0.25);
            }
        }

        + .icons {
            box-shadow: 0 0 0 2px hsla(var(--color-white), 1), 0 0 0 3px hsla(var(--color), 0.25);
        }
    }

    &:checked {

        + .icons {
            background: hsl(var(--focus-color));
            border-color: hsl(var(--focus-color));

            svg {
                transform: scale(0.75);
            }
        }
    }

    &[disabled] {

        + .icons {
            opacity: 0.5;
            pointer-events: none;
        }
    }
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.required {
    position: absolute;
    top: 0;
    left: calc(var(--size) + calc(0.5 * var(--gap)));
    color: hsl(var(--global_danger-color, var(--color-red)));
    font-size: var(--font-size-00);
}

.error {
    margin-left: calc(var(--size) + calc(0.5 * var(--gap)));
    display: block;
}

.icons {
    width: var(--size);
    height: var(--size);
    margin-right: calc(0.5 * var(--gap));
    display: flex;
    background-color: hsl(var(--background));
    color: hsl(var(--color-white));
    line-height: 0;
    transition: all var(--transition-out) var(--transition-timing);
    border-radius: calc(0.5 * var(--border-radius));
    border: 1px solid hsla(var(--border-color), 0.5);

    svg {
        transform: scale(0);
        transition: transform var(--transition-out) var(--transition-timing);
    }
}
